/* eslint-disable no-useless-escape */
export const Constants = {
  USER_TYPE_USER: "user_type_user",
  TOKEN_USER: "token_user",
  USER_TYPE_ADMIN: "user_type_admin",
  TOKEN_ADMIN: "token_admin",
  NORMAL_USER_INFO: "normal_user_info",
  ADMIN_USER_INFO: "admin_user_info",
  SHOP_ID: "shop_id",
  SHOP_NAME: "shop_name",
  POINT: "point",
  USER_ID: "user_id",
  USER_NAME: "user_name",
  POINT_TYPE_BONUS: {
    login: 1,
    sign_up: 2,
    after_click_content: 3,
    after_purchase: 4,
    grant_to_user: 5,
    update_all_info: 6,
    automatic_grant: 7,
    after_giving_slag: 8,
    setting_all_content: 9,
    after_answer_question: 10,
  },
  PAYMENT_METHOD: {
    oneTime: 0,
    periodic: 1,
    cancelled: 2,
  },
  ADMIN: "admin",
  PAYMENT_METHODS: ["クレジットカード", "Stripe", "後払い"],
  REGEX_EMAIL: /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@[\w-]+(\.[\w-]+)*(\.\w{2,18})+$/,
  EMAIL: "email",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  SEX: "sex",
  BIRTHDAY: "birthday",
  PAYMENT_TYPE: {
    onetime: 0,
    subscription: 1,
  },
  LIMIT_CSV: 10,
  MAX_LENGTH: 50,
  ERROR_SYSTEM: "システムエラーが発生しました",
  LIMIT: 6,
  LIMIT_URL: 6,
  CONTENT: "content",
  LIMIT_POINT: 999999999999999,
  IS_REMEMBER_USER: "is_remember_user",
  IS_REMEMBER_ADMIN: "is_remember_admin",
  EXPIRES_AT_USER: "expires_at_user",
  EXPIRES_AT_ADMIN: "expires_at_admin",
  // REGEX_URL:
  //   /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  REGEX_URL:
    /^(http|https):\/\/(([a-zA-Z0-9$\-_.+!*'(),;:&=]|%[0-9a-fA-F]{2})+@)?(((25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])(\.(25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])){3})|localhost|([a-zA-Z0-9\-\u00C0-\u017F]+\.)+([a-zA-Z]{2,}))(:[0-9]+)?(\/(([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*(\/([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*)*)?(\?([a-zA-Z0-9$\-_.+!*'(),;:@&=\/?]|%[0-9a-fA-F]{2})*)?(\#([a-zA-Z0-9$\-_.+!*'(),;:@&=\/?]|%[0-9a-fA-F]{2})*)?)?$/,
  REGEX_TEXT: /^[A-Za-z\d@$!-_.%*#?&^]+$/,
  REGEX_NUMBER: /^[0-9]+$/,
  LIMIT_BOTTOM: 4,
  LIST_ICON: [
    "person",
    "person-circle",
    "person-fill",
    "person-lines-fill",
    "person-square",
    "file-earmark-person",
    "file-earmark-person-fill",
    "file-person",
    "file-person-fill",
    "person-check",
    "person-badge-fill",
    "person-check-fill",
    "people",
    "people-fill",
    "card-checklist",
    "card-list",
    "list",
    "list-check",
    "view-list",
    "alarm",
    "alarm-fill",
    "bar-chart",
    "bar-chart-fill",
    "bar-chart-line-fill",
    "basket",
    "basket2-fill",
    "bell",
    "bell-fill",
    "book",
    "book-fill",
    "bookmark",
    "bookmark-check-fill",
    "bookmark-fill",
    "bookmarks-fill",
    "briefcase-fill",
    "calendar",
    "calendar2-check-fill",
    "calendar-check",
    "calendar-fill",
    "cart2",
    "cart-check-fill",
    "cart-fill",
    "chat-dots",
    "chat-fill",
    "clock-fill",
    "display-fill",
    "house-fill",
    "question-octagon-fill",
    "stickies-fill",
    "tag",
    "tags-fill",
    "wallet2",
    "unlock-fill",
    "search",
    "capslock-fill",
  ],
  STATUS_USER: [
    { label: "利用不可", value: 2 },
    { label: "利用中", value: 1 },
    { label: "退会済み", value: 0 },
  ],
  SHOULD_SEND_REGISTERED_EMAIL: [
    { label: '送信する', value: 1 },
    { label: '送信しない', value: 0 },
  ],
  STATUS_INQUIRY: ["未返信", "返信済"],
  CATEGORY_FIXED_PAGE: [
    { text: "サンクスページ", value: 0 },
    { text: "利用規約", value: 1 },
    { text: "特定商取引法", value: 2 },
    { text: "プライバシーポリシー", value: 3 },
    { text: "ヘルプページ（Q&A）", value: 4 },
    { text: "1カラムページ", value: 5 },
    { text: "その他", value: 7 },
  ],
  CATEGORY_FIXED_PAGE_CDEA: [
    { text: "サンクスページ", value: 0 },
    { text: "利用規約", value: 1 },
    { text: "特定商取引法", value: 2 },
    { text: "プライバシーポリシー", value: 3 },
    { text: "ヘルプページ（Q&A）", value: 4 },
    { text: "1カラムページ", value: 5 },
    { text: "フリー入力", value: 6 },
    { text: "その他", value: 7 },
  ],
  LIST_CONTENT: "list_content",
  PAGE_PREVIEW: "page_preview",
  PREVIEW_COLUMN_PAGE: "column_page",
  TOKEN_CDEA: "token_cdea",
  EXPIRES_AT_CDEA: "expires_at_cdea",
  IS_REMEMBER_CDEA: "is_remember_cdea",
  USER_TYPE_CDEA: "user_type_cdea",
  CDEA_ADMIN: "cdea_admin",
  AMOUNT_OPTION_BOTTOM: 4,
  LIST_URL: "list_url",
  EMAIL_USER: "email_user",
  EMAIL_ADMIN: "email_admin",
  LIST_SLAG: "list_slag",
  NO_FIXED_PAGE: "no_fixed",
  NO_URL: "no_url",
  NO_CONTENT: "no_content",
  CONTENT_TYPE: {
    survey: 0,
    one_time_offer: 1,
    countdown: 2,
  },
  ALLOW_PRE_SALE: {
    not_allow: 0,
    allow: 1,
  },
  IS_VOTE: {
    regular: 0,
    vote: 1,
  },
  OCCUPATION: [
    "経営者・役員",
    "会社員（総合職）",
    "会社員（一般職）",
    "契約社員・派遣社員",
    "パート・アルバイト",
    "公務員（教職員除く）",
    "教職員",
    "医療関係者",
    "自営業・自由業",
    "専業主婦・主夫",
    "大学生・大学院生",
    "専門学校生・短大生",
    "高校生",
    "士業（公認会計士・弁護士・税理士・司法書士）",
    "無職",
    "定年退職",
    "その他",
  ],
  PAGE404: "page404",
  PAGE404ADMIN: "page404admin",
  KEYWORD_FILTER: "keyword_filter",
  // eslint-disable-next-line no-irregular-whitespace
  FULL_WIDTH: /([^０-９ぁ-んァ-ン一-龥ａ-ｚＡ-Ｚ　 。ー])/g,
  NO_TRIGGER: "no_trigger",
  LIMIT_AUTHOR: 20,
  LIMIT_CONTENT_AUTHOR: 9,
  WINDOWS_HOVER_TYPE: {
    countdown_date: 0,
    countdown_time: 1,
    one_time_offer: 2,
    regular: 3,
  },
  USER_TYPE_NORMAL: 4,
  SUBCRIPTION: "subscription",
  CHECK_PASSWORD: /^[A-Za-z\d@$!%*#?&^]{8,25}$/,
  CHECK_PASSWORD_OWNER: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^]{8,25}$/,
  PAYMENT_TYPE_DEFAULT: [
    { text: "Stripe単発決済", value: 1 },
    { text: "Stripe定期購読", value: 2 },
    { text: "PayPal単発決済", value: 3 },
    { text: "PayPal定期購読", value: 4 },
  ],
  OPTION_INTERVAL: [
    { label: "日", value: 1 },
    { label: "週", value: 2 },
    { label: "月", value: 3 },
    { label: "年", value: 4 },
  ],
  USER_TYPE_DEFAULT: {
    cdea: 0,
    owner: 1,
    admin: 2,
    author: 3,
    user_normal: 4,
  },
  REGEX_KANA: /^([ァ-ン]|ー)+$/,
  FIXED_PAGE_TYPE: {
    column_page: 5,
    input_free: 6,
  },
  HEADER_FIXED_PAGE_TYPE: {
    white_page: 0,
    frame: 1,
    menu_frame: 2,
    menu_no_frame: 3,
  },
  BI_SHOP: "bi_shop",
  DOMAIN: location.protocol + "//" + location.hostname,
  DETAIL_SHOP: "detail_shop",
  EXPIRE_CONTENT_LIB: {
    all: 0,
    each: 1,
  },
  CONDITION_DISPLAY_CONTENT: {
    NOT_SET: 0,
    SET_CONTENT: 1,
    SET_SLAG: 2,
    ALL_USER: 3,
    HIDDEN_ALL: 4,
  },
  CONDITION_URL: {
    period: 0,
    count: 1,
    mail: 2,
    other: 3,
  },
  PERIOD_URL: {
    time: 0,
    date: 1,
  },
  COUNT_DASHBOARD: 6,
  USER_INPUT: {
    name_email: 0,
    name_email_phone: 1,
    name_email_phone_address: 2,
  },
  BOTTOM_SLICK: 5,
  URL_BE_API:
    process.env.VUE_APP_ENV !== 'local' ?
      location.protocol +
      "//" +
      location.hostname +
      (location.port ? ":" + location.port : "") +
      "/be/api"
      : process.env.VUE_APP_ROOT_API_DEV,
  // URL_BE_API: process.env.VUE_APP_ROOT_API,
  // @NOTE
  // APIエンドポイントが今まではドメインごとに異なっていたが、下側の「URL_BE_API」使用すると同じドメイン（shop1.cdea.site）を使用することになる。
  // 下側の利用のためには全てのAPIリクエストにshop_idを含んでいる必要がある。
  // ドメインからショップIDを特定するような箇所が存在しない確認が取れていないため、上側の「URL_BE_API」を使用するように戻しておく
  DOMAIN_DEFAULT: "cdeashop.tk",
  TITLE_SHOP: "title_shop",
  REVIEW_PAGE: "review_page",
  LIMIT_MESSAGE: 10000,
  INFOR_ORDER: "info_orders",
  TIME_START: "time_start",
  MESSAGE_COPY: "コピーしました！",
  INFO_CONTENT: "info_content",
  PAYPAL_URL: "https://api-m.paypal.com", //https://api-m.sandbox.paypal.com
  URL_BE: location.protocol + "//" + location.hostname + "/be",
  // URL_BE: process.env.VUE_APP_ROOT_API, // @NOTE 「URL_BE_API」と同様
  SENTENCE: "sentence",
  ID_MODAL_FIXED: "idModalFixed",
  ID_MODAL_FIXED_LOGIN: "idModalFixedLogin",
  ID_MODAL_FIXED_SIGNUP: "idModalFixedSignup",
  // 外部決済URL方式の選択項目(入力フォームスキップ有無)
  EXTERNAL_PAYMENT_URL_SKIP: {
    non_skip: 0,
    skip: 1,
  },
  EXTERNAL_PAYMENT_URL_SKIP_LIST: {
    0: 'スキップしない',
    1: 'スキップしてすぐ決済ページへ',
  },
  // 外部決済URL方式の選択項目(認証方式)
  EXTERNAL_PAYMENT_APPROVAL_TYPE: {
    manual: 0,
    auto: 1,
    conditional_manual: 2,
  },
  EXTERNAL_PAYMENT_APPROVAL_TYPE_LIST: {
    0: '一律手動承認',
    1: '一律自動承認',
    2: '原則自動 + 不正利用に該当した場合手動',
  },
  // 外部決済用トークン
  EXTERNAL_PAYMENT_TOKEN: "external_payment_token",
  // 外部決済Referrer確認項目
  EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE: {
    perfect: 0,
    prefix: 1,
    partial: 2,
  },
  EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE_LIST: {
    0: '完全一致',
    1: '前方一致',
    2: '部分一致',
  },
  // 注文ステータス
  STATUS_ORDER: {
    not_accepted: 1,
    accepted: 2,
    cancel: 3,
    cancel_manual: 4,
    canel_not_refund: 5,
    cycle_subcription: 6,
    payment_failed: 7,
    deemed: 8,
    external_payment_transition: 11,
    external_payment_user_not_login: 12,
    external_payment_manual_approvaling: 13,
    // external_payment_manual_approval_not_accepted: 14,
    // external_payment_manual_approval_accepted: 18,
    // external_payment_auto_accepted:19,
  },
  STATUS_ORDER_LIST: {
    1: '確認待ち（銀行振込）',
    2: '承認済み',
    3: 'キャンセル（結果：自動返金済み）',
    4: 'キャンセル（結果：自動返金不可・手動で返金）',
    5: 'キャンセル（返金を伴わない）',
    6: '自動決済',
    7: '決済失敗',
    8: 'みなし購入',
    11: '外部決済（申込中）',
    12: '外部決済（ユーザー未確認）',
    13: '外部決済（手動承認中）',
    // 14: '外部決済（手動承認拒否）',
    // 18: '外部決済（手動承認許可）',
    // 19: '外部決済（自動承認許可）',
  },
  EMAIL_TYPE: {
    regist_member_to_admin                  : 0,
    regist_to_user                          : 1,
    purchase_to_admin                       : 2,
    purchase_to_buyer                       : 3,
    after_card_payment_to_customer          : 4,
    apply_bank                              : 5,
    confirm_tranfer                         : 6,
    subcriber                               : 7,
    unsubcriber                             : 8,
    withdrawal                              : 9,
    user_change_password_complete           : 10,
    admin_register_user                     : 11,
    user_purchase_content                   : 12,
    user_change_password                    : 13,
    user_forgot_password                    : 14,
    regist_without_pass                     : 15,
    external_payment_manual_to_admin        : 21,
    external_payment_change_manual_to_admin : 22,
    external_payment_auto_to_admin          : 23,
    external_payment_approval_check_to_user : 24,
    external_payment_approved_to_user       : 25,
  },
  AUTHOR_EMAIL_TYPE: {
    external_payment_approval_check_to_user : 24,
    external_payment_approved_to_user       : 25,
  },
  PRICE_BY_USER_INPUT: 'price_by_user_input',
  POINTS_BY_USER_INPUT: 'points_by_user_input',
};
